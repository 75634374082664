<template>
    <div class="malt-footer-information" :aria-label="slice.primary.accessibilityLabel || undefined">
        <MaltImg src="/cms-front/maltLogo.png" alt="Malt Logo" loading="lazy" width="120" height="35" />
        <p v-if="address !== undefined">
            <span v-for="(line, index) in address" :key="`address-line-${index}`">
                {{ line }}
                <br />
            </span>
        </p>

        <p>
            <ServerSideNavigationPrismicLink
                v-if="isLink(slice.primary.supportText, slice.primary.supportLink)"
                :field="props.slice.primary.supportLink"
            >
                {{ props.slice.primary.supportText }}
            </ServerSideNavigationPrismicLink>
        </p>
        <a v-if="supportEmail && slice.primary.supportEmailLinkText" :href="`mailto:${supportEmail}`">
            {{ props.slice.primary.supportEmailLinkText }}
        </a>
        <div class="social-media-icons">
            <div v-for="item in socials" :key="item.name">
                <SocialMediaIcon v-if="item.link" :name="item.name" :link="item.link" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {MaltImg} from '#components';
    import {getSliceComponentProps} from '@prismicio/vue';
    import {useAsyncData, useHostSettings} from '#imports';
    import {usePrismicFieldHelpers} from '@navbar-unlogged/composables';
    import type {MaltFooterInfoSliceSlice} from '@prismicio-types';
    import ServerSideNavigationPrismicLink from '@navbar-unlogged/components/ServerSideNavigationPrismicLink.vue';
    import SocialMediaIcon from '@navbar-unlogged/components/SocialMediaIcon.vue';

    const props = defineProps(getSliceComponentProps<MaltFooterInfoSliceSlice>());

    const SOCIAL_MEDIAS = ['facebook', 'linkedIn', 'instagram'] as const;

    const {isLink} = usePrismicFieldHelpers();

    const {data: microdata} = await useAsyncData('hostSettings', () => useHostSettings());

    const socials = SOCIAL_MEDIAS.map((name) => ({
        name,
        link: microdata.value ? microdata.value[name] : undefined,
    })).filter((social) => social.link);

    const brTagExp = /<\/?br\s*\/?>/gi; // split by line breaks to avoid use of v-html
    const logoUrl = microdata.value?.brandLogo;
    const address = microdata.value?.address?.split(brTagExp);
    const supportEmail = microdata.value?.customerSupportEmail;
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;
    @use '@navbar-unlogged/scss/variables.scss' as vars;

    .malt-footer-information {
        display: flex;
        flex-direction: column;
        gap: var(--joy-core-spacing-9);
        font-size: var(--joy-font-size-primary-400);
        font-family: var(--joy-font-family);
        flex: 1;
        order: -1;

        p {
            line-height: var(--joy-line-height-large);
            font-weight: var(--joy-font-weight-normal);
            color: var(--joy-color-neutral-50);
        }

        @include mq.screen_sm {
            gap: 32px;
            img {
                margin-bottom: 16px;
            }
        }

        .social-media-icons {
            @include vars.flex-row-align-center;
            gap: 24px;

            @include mq.screen_sm {
                justify-content: space-between;
                margin-bottom: 24px;
            }
        }

        a {
            font-weight: var(--joy-font-weight-normal);
            color: var(--joy-color-neutral-50);
        }
    }
</style>
